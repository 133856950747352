import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  Flex,
  Select,
  Text,
  Input,
  Box,
  FormLabel,
  FormControl,
  Textarea,
} from "@chakra-ui/react";

import * as FiIcons from "react-icons/fi";

import { updateOrdersStatus } from "../../../../store/orders/ordersSlice";

import theme from "../../../global/theme";
import ReverseOrderStatusModal from "./ReverseOrderStatusModal";

const UpdateOrdersStatusModal = ({ data, onClose, reverse = false }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const orders = useSelector((state) => state.orders);
  const auth = useSelector((state) => state.auth);

  const {
    register,
    handleSubmit,
    getValues,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      orders: data,
      status: data[0].status,
      holding_to: null,
    },
  });

  const isItemsStatusDiff = () => {
    for (let i = 0; i < data.length; i++) {
      const compareItem = data?.find((el) => el.status !== data[i].status);
      if (compareItem) return true;
    }
    return false;
  };

  watch(["status"]);

  const currentStatus = data[0].status;

  return (
    <Modal isOpen={true} onClose={onClose}>
      <ModalOverlay />
      <ModalContent borderRadius={20} padding={4} bg={theme.dark}>
        <form
          onSubmit={handleSubmit((values) => {
            values.reverse = reverse;
            dispatch(updateOrdersStatus(values))
              .unwrap()
              .then((_) => {
                document.querySelectorAll("button").forEach((el) => {
                  el.remove();
                });
                alert("تم تحديث حالة الطلبات بنجاح!");
                window.location.reload();
              })
              .catch((_) => {
                onClose();
              });
          })}
        >
          {reverse ? (
            <>
              <ReverseOrderStatusModal
                isItemsStatusDiff={isItemsStatusDiff}
                register={register}
                onClose={onClose}
                currentStatus={currentStatus}
                auth={auth}
                errors={errors}
                getValues={getValues}
                orders={orders}
              />
            </>
          ) : (
            <>
              <ModalHeader
                textAlign="center"
                color="orange"
                textTransform="uppercase"
                fontSize={22}
              >
                تحديث حالات الطلبات
              </ModalHeader>
              <ModalBody>
                {isItemsStatusDiff() ? (
                  <Box color={theme.light} textAlign="center">
                    حالات الطلب غير موحده
                  </Box>
                ) : (
                  <>
                    <Select
                      bg={theme.bg}
                      color={theme.dark}
                      {...register(
                        "status",
                        getValues("status") !== "holding"
                          ? {
                              required: `${t("validation.required")}`,
                            }
                          : {}
                      )}
                    >
                      {auth.user.role === "admin" &&
                        (currentStatus === "pending" ? (
                          <>
                            <option
                              style={{ color: theme.dark }}
                              value="preparing"
                            >
                              تحضير للشحن
                            </option>
                            <option
                              style={{ color: theme.dark }}
                              value="holding"
                            >
                              تاجيل الطلب
                            </option>
                            <option
                              style={{ color: theme.dark }}
                              value="declined1"
                            >
                              ملغي
                            </option>
                            <option
                              style={{ color: theme.dark }}
                              value="cancelled"
                            >
                              معلومات غير صحيحة
                            </option>
                          </>
                        ) : currentStatus === "preparing" ? (
                          <>
                            <option
                              style={{ color: theme.dark }}
                              value="shipped"
                            >
                              في الشحن
                            </option>
                            <option
                              style={{ color: theme.dark }}
                              value="declined2"
                            >
                              ملغي
                            </option>
                          </>
                        ) : currentStatus === "holding" ? (
                          <>
                            <option
                              style={{ color: theme.dark }}
                              value="preparing"
                            >
                              تحضير للشحن
                            </option>
                            <option
                              style={{ color: theme.dark }}
                              value="declined1"
                            >
                              ملغي
                            </option>
                          </>
                        ) : currentStatus === "shipped" ? (
                          <>
                            <option
                              style={{ color: theme.dark }}
                              value="available"
                            >
                              تم التسليم
                            </option>
                            <option style={{ color: theme.dark }} value="skip">
                              جار الاسترجاع
                            </option>
                          </>
                        ) : currentStatus === "skip" ? (
                          <>
                            <option
                              style={{ color: theme.dark }}
                              value="returned1"
                            >
                              مرتجع
                            </option>
                          </>
                        ) : currentStatus === "available" ? (
                          <>
                            <option
                              style={{ color: theme.dark }}
                              value="collected"
                            >
                              تم التحصيل
                            </option>
                            <option
                              style={{ color: theme.dark }}
                              value="ask_to_return"
                            >
                              طلب العميل الاسترجاع
                            </option>
                          </>
                        ) : currentStatus === "ask_to_return" ? (
                          <>
                            <option
                              style={{ color: theme.dark }}
                              value="returned2"
                            >
                              مرتجع
                            </option>
                          </>
                        ) : currentStatus === "collected" ? (
                          <option
                            style={{ color: theme.dark }}
                            value="ask_to_return"
                          >
                            طلب العميل الاسترجاع
                          </option>
                        ) : (
                          <option style={{ color: theme.dark }} value="">
                            لا يوجد حالات اخرى
                          </option>
                        ))}
                    </Select>
                    {["skip"].includes(getValues("status")) && (
                      <>
                        <FormControl mt={6}>
                          <FormLabel
                            fontWeight="bold"
                            textTransform="capitalize"
                            color="white"
                          >
                            سبب إرجاع الاوردر
                          </FormLabel>
                          <Select
                            mt={6}
                            bg={theme.bg}
                            defaultValue={data[0]?.skipping_reason}
                            color={theme.dark}
                            {...register("skipping_reason", {
                              required: `${t("validation.required")}`,
                            })}
                          >
                            <option
                              style={{ color: theme.dark }}
                              value="refused_with_rep"
                            >
                              العميل رفض الشحنة مع المندوب
                            </option>
                            <option
                              style={{ color: theme.dark }}
                              value="no_response_to_rep"
                            >
                              العميل لا يرد علي المندوب
                            </option>
                            <option
                              style={{ color: theme.dark }}
                              value="client_evaded"
                            >
                              العميل تهرب من الإستلام
                            </option>
                            <option
                              style={{ color: theme.dark }}
                              value="canceled_via_phone"
                            >
                              العميل بلغ المندوب في التليفون بالإلغاء
                            </option>
                            <option
                              style={{ color: theme.dark }}
                              value="received_elsewhere"
                            >
                              العميل استلم من مكان تاني
                            </option>
                            <option
                              style={{ color: theme.dark }}
                              value="other_reason"
                            >
                              سبب آخر
                            </option>
                          </Select>
                        </FormControl>
                        <FormControl mt={6}>
                          <FormLabel
                            fontWeight="bold"
                            textTransform="capitalize"
                            color="white"
                          >
                            {t("pages.orders.note")}
                          </FormLabel>
                          <Textarea
                            bg={theme.bg}
                            color={theme.dark}
                            border="none"
                            defaultValue={data[0]?.skipping_reason_note}
                            placeholder={"برجاء توضيح سبب  إسترجاع الاوردر"}
                            _placeholder={{ textTransform: "capitalize" }}
                            {...register("skipping_reason_note", {
                              maxLength: {
                                value: 150,
                                message: `${t("validation.max_length")} 150`,
                              },
                            })}
                          />
                          {errors.skipping_reason_note?.message && (
                            <Text color="red.600" marginTop={2}>
                              {errors.skipping_reason_note.message}
                            </Text>
                          )}
                        </FormControl>
                      </>
                    )}
                    {["declined1", "declined2"].includes(
                      getValues("status")
                    ) && (
                      <>
                        <FormControl mt={6}>
                          <FormLabel
                            fontWeight="bold"
                            textTransform="capitalize"
                            color="white"
                          >
                            سبب الإلغاء
                          </FormLabel>
                          <Select
                            mt={6}
                            bg={theme.bg}
                            color={theme.dark}
                            defaultValue={data[0]?.canceling_reason}
                            {...register("canceling_reason", {
                              required: `${t("validation.required")}`,
                            })}
                          >
                            <option
                              style={{ color: theme.dark }}
                              value="purchased_elsewhere"
                            >
                              العميل اشتري من مكان تاني
                            </option>
                            <option
                              style={{ color: theme.dark }}
                              value="not_needed_now"
                            >
                              مش محتاجه دلوقتي هبقي اطلبه تاني
                            </option>
                            <option
                              style={{ color: theme.dark }}
                              value="denied_order"
                            >
                              العميل بيقول ما طلبتش حاجة
                            </option>
                            <option
                              style={{ color: theme.dark }}
                              value="unable_to_reach"
                            >
                              صعوبة الوصول للعميل
                            </option>
                            <option
                              style={{ color: theme.dark }}
                              value="price_issue"
                            >
                              بسبب السعر
                            </option>
                            <option
                              style={{ color: theme.dark }}
                              value="other_reason"
                            >
                              سبب آخر
                            </option>
                          </Select>
                        </FormControl>
                        <FormControl mt={6}>
                          <FormLabel
                            fontWeight="bold"
                            textTransform="capitalize"
                            color="white"
                          >
                            {t("pages.orders.note")}
                          </FormLabel>
                          <Textarea
                            bg={theme.bg}
                            color={theme.dark}
                            defaultValue={data[0]?.canceling_reason_note}
                            border="none"
                            placeholder={"برجاء توضيح سبب إلغاء الاورد"}
                            _placeholder={{ textTransform: "capitalize" }}
                            {...register("canceling_reason_note", {
                              maxLength: {
                                value: 150,
                                message: `${t("validation.max_length")} 150`,
                              },
                            })}
                          />
                          {errors.canceling_reason_note?.message && (
                            <Text color="red.600" marginTop={2}>
                              {errors.canceling_reason_note.message}
                            </Text>
                          )}
                        </FormControl>
                      </>
                    )}

                    {errors.status?.message && (
                      <Text color="red.600" marginTop={2}>
                        {errors.status.message}
                      </Text>
                    )}

                    {getValues("status") === "holding" && (
                      <>
                        <Input
                          mt={4}
                          color={theme.light}
                          colorScheme={theme.dark}
                          type="datetime-local"
                          {...register("holding_to", {
                            required: {
                              value: true,
                              message: `${t("validation.required")}`,
                            },
                          })}
                        />
                        {errors.holding_to?.message && (
                          <Text color="red.600" marginTop={2}>
                            {errors.holding_to.message}
                          </Text>
                        )}
                      </>
                    )}
                  </>
                )}
              </ModalBody>

              <ModalFooter>
                <Flex justifyContent="flex-end">
                  <Button
                    type="submit"
                    rightIcon={<FiIcons.FiSave />}
                    color="white"
                    bg="green"
                    paddingInline={4}
                    paddingBlock={2}
                    height="auto"
                    textTransform="capitalize"
                    isLoading={orders.isLoading}
                    _hover={{ background: "green" }}
                  >
                    {t("general.save")}
                  </Button>
                  <Button
                    type="button"
                    onClick={onClose}
                    rightIcon={<FiIcons.FiMinimize2 />}
                    color="white"
                    bg="red.600"
                    paddingInline={4}
                    paddingBlock={2}
                    height="auto"
                    textTransform="capitalize"
                    marginInlineStart={4}
                    isLoading={orders.isLoading}
                    _hover={{ background: "red.600" }}
                  >
                    {t("general.close")}
                  </Button>
                </Flex>
              </ModalFooter>
            </>
          )}
        </form>
      </ModalContent>
    </Modal>
  );
};

export default UpdateOrdersStatusModal;
